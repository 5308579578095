<script setup>
import { to } from "await-to-js";
import { ref, reactive, onMounted, computed } from "vue";
//import { get } from "lodash";
import { interceptor } from "@dutypay/utilities";
import { useEnvStore } from "@dutypay/store-modules";
const loading = ref(false);
const users = ref([]);
const queriedUserCount = ref(0);
const totalUserCount = ref(0);
const envStore = useEnvStore();

const pageSizeOptions = ref([
  {label: '10', value: "10"},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  search: "",
  pageNumber: "1",
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getUsers()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber,10) > 1;
});
async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) - 1).toString();
  await getUsers();
}

const hasNextPage = computed(() => {
  return(
      parameters.pageNumber <
      Math.ceil(parseInt(queriedUserCount.value,10) / parseInt(parameters.pageSize,10))
  );
});
async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) + 1).toString();
  await getUsers();
}

async function getUsers() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
    interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/users-list`, {
      params: {
        search: parameters.search,
        pageNumber: parameters.pageNumber,
        pageSize: parameters.pageSize,
      },
    })
  );
  if (err) {
    loading.value = false;
    throw err;
  }
  users.value = [];
  users.value = res.data.usersList;
  queriedUserCount.value = res.data.queriedUserCount;
  totalUserCount.value = res.data.totalUserCount;
  loading.value = false;
}
onMounted(async () => {
  await getUsers();
});
</script>

<script>
import PaginationNavigationComponent from '../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "UsersPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Users</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" :xl="8" :lg="8" :sm="24" :xs="24" class="searchform">
        <el-form-item style="flex-grow: 1">
          <el-input
            v-model="parameters.search"
            @click="getUsers()"
            @change="parameters.pageNumber = 1"
            placeholder="Search for sp.id, hs.id, email"
            :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getUsers"> Search </el-button>
        </el-form-item>
      </el-col>
      <el-col
        :span="16"
        :xl="16"
        :lg="16"
        :sm="24"
        :xs="24"
        class="pagesize"
        style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="Users per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="users" stripe style="width: 100%">
          <el-table-column prop="primary.email.address" label="E-Mail" />
          <el-table-column prop="hs.user.id" label="HubSystem ID" />
          <el-table-column prop="sp.user.id" label="SharePoint ID" />
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="$router.push('/user-grants/' + scope.row.hs.user.id)"
                >Edit Grants</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
