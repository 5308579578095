<script setup>
import {to} from "await-to-js";
import {onMounted, reactive, ref, computed} from "vue";
import {set, isEmpty, omitBy} from "lodash";
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);
const infrastructureCompoments = ref([]);
const frontendPages = ref([]);


const queriedFrontendPageCount = ref(0);
const totalFrontendPageCount = ref(0);
const envStore = useEnvStore();

const pageSizeOptions = ref([
  {label: '10', value: '10'},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  searchPathName: "",
  pageNumber: "1",
  infrastructureComponentID: ""
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getFrontendPages()
}


const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber, 10) > 1;
});

async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) - 1).toString();
  await getFrontendPages();
}

const hasNextPage = computed(() => {
  return (
      queriedFrontendPageCount.value < totalFrontendPageCount.value)
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
  await getFrontendPages();
}

// TODO: Needs an improvement about permission properties if refactoring is done
async function updateFrontendPages(data) {
  loading.value = true;
  try {
    let err, res;
    [err, res] = await to(
        interceptor.patch(`${envStore.apiUrls.dutypay}/api/v0/frontend-pages/${data.hs.frontend.page.id}`, data)
    );
    if (err) {
      ElNotification({
        title: "Error to update frontend-page",
        type: "error",
        message: err.message
      });
    } else {
      console.log("res", res.data)
      ElNotification({
        title: res.data.name,
        type: "success",
        message: res.data.message
      });
    }
    loading.value = false;
  } catch (err) {
    ElNotification({
      title: "Update cancelled",
      type: "error",
      message: err.message
    });
    loading.value = false;
  }
}


async function getInfrastructureCompoments() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/infrastructure-components`, {
        params: {type: 'app'}
      })
  );

  if (err) {

    if (err.response.data.name === "Not Found Error") {
      infrastructureCompoments.value = [];
    } else {
      loading.value = false;
      throw err;
    }
  } else {
    infrastructureCompoments.value = res.data;
  }
}

function getInfrastructureCompomentNameById(id) {
  let result = infrastructureCompoments.value.filter(function (row) {
    return row['hs.infrastructure.component.id'] === id
  });

  if (isEmpty(result[0])) {
    return "Unknown"
  }
  return result[0].name;
}


async function getFrontendPages() {
  loading.value = true;
  let err, res;
  const filteredParameters = omitBy(parameters, value => isEmpty(value));

  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/frontend-pages`, {
        params: filteredParameters,
      })
  );

  if (err) {

    if (err.response.data.name === "Not Found Error") {
      frontendPages.value = [];
    } else {
      loading.value = false;
      throw err;
    }
  } else {
    frontendPages.value = res.data.result.frontendPageList;
  }

  let updatedFrontendPages = []
  for (let frontendPage of frontendPages.value) {
    frontendPage = set(frontendPage, 'infrastructure.component.name', getInfrastructureCompomentNameById(frontendPage.hs.infrastructure.component.id))
    updatedFrontendPages.push(frontendPage)
  }
  frontendPages.value = updatedFrontendPages;

  queriedFrontendPageCount.value = res.data.result.sqlQueriedFrontPageCurrentCount;
  totalFrontendPageCount.value = res.data.result.sqlQueriedFrontPageTotalCount;
  loading.value = false;
}
onMounted(async () => {
  await getInfrastructureCompoments();
  await getFrontendPages();
});
</script>

<script>
import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "FrontendPagesPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>FrontendPages</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16" :xl="16" :lg="16" :sm="32" :xs="32" class="searchform">
        <el-form-item style="padding-left: 1rem; flex-grow: 1">
          <el-input
              v-model="parameters.searchPathName"
              @change="parameters.pageNumber = '1'"
              placeholder="Search for path.name"
              :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 1">

          <el-select v-model="parameters.infrastructureComponentID" placeholder="Select Component" :clearable="true">
            <el-option
                v-for="(item) in infrastructureCompoments"
                :key="item.id"
                :label="item.name"
                :value="item['hs.infrastructure.component.id'] || 'undefined'"

            />
          </el-select>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getFrontendPages"> Search</el-button>
        </el-form-item>
      </el-col>
      <el-col
          :span="8"
          :xl="8"
          :lg="8"
          :sm="24"
          :xs="24"
          class="pagesize"
          style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="FrontendPages per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="frontendPages" stripe style="width: 100%">
          <!--<el-table-column prop="hs.infrastructure.component.id" label="Infrastructure Component ID"/>-->
          <el-table-column prop="infrastructure.component.name" label="Infrastructure Component Name"/>
          <el-table-column prop="path.name" label="Path name"/>
          <!-- TODO: Needs an update in the future about select ids or values -->
          <el-table-column prop="permission.requirement.ids" label="Permission Requirement IDs">
            <template #default="scope">
              <el-input type="textarea" v-model="scope.row.permission.requirement.ids"/>
            </template>
          </el-table-column>
          <el-table-column prop="permission.requirement.values" label="Permission Requirement Values">
            <template #default="scope">
              <el-input type="textarea" v-model="scope.row.permission.requirement.values"/>
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="updateFrontendPages(scope.row)"
              >Save
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
{
  "en-US": {
  }
}
</i18n>
