import UsersPage from "./pages/UsersPage";
import APIRoutesPage from "./pages/APIRoutes/APIRoutesPage";
import FrontendPagesPage from "./pages/FrontendPages/FrontendPagesPage";
import CompaniesPage from "./pages/CompaniesPage";
import ResourcesPage from "./pages/PBAC/ResourcesPage";
import PermissionsPage from "./pages/PBAC/PermissionsPage";
import DataAccessControlUsersPage from "./pages/DataAccessControl/UsersPage";
import DataAccessControlSettingsPage from "./pages/DataAccessControl/SettingsPage";
import ResourceActionsPage from "./pages/PBAC/ResourceActionsPage";
import PermissionGroupsPage from "./pages/PBAC/PermissionGroupsPage";
import UserPermissionGroupsPage from "./pages/PBAC/UserPermissionGroupsPage";
import ResultRestrictorsPage from "./pages/PBAC/ResultRestrictorsPage";
import PropertiesRestrictorsPage from "./pages/PBAC/PropertiesRestrictorsPage";

//import ToolsPage from "./pages/ToolsPage";
//import OSSCorrection from "./pages/ToolsPage/OSSCorrection";
//import OSSCorrectionProjectDetails from "./pages/ToolsPage/OSSCorrection/OSSCorrectionProjectDetails";

const pages = [
    {
        path: '/',
        redirect: '/users'
    },
    {
        path: '/users',
        name: 'UsersPage',
        component: UsersPage
    },
    {
        path: '/frontend-pages',
        name: 'FrontendPagesPage',
        component: FrontendPagesPage
    },
    {
        path: '/api-routes',
        name: 'APIRoutesPage',
        component: APIRoutesPage
    },
    {
        path: '/companies',
        name: 'CompaniesPage',
        component: CompaniesPage
    },
    {
        path: '/data-access-control',
        redirect: '/data-access-control/users',
        children: [
            {
                path: 'users',
                name: 'DataAccessControlUsersPage',
                component: DataAccessControlUsersPage
            },
            {
                path: 'settings',
                name: 'DataAccessControlSettingsPage',
                component: DataAccessControlSettingsPage
            }
        ]
    },
    {
        path: '/pbac',
        redirect: '/pbac/resources',
        children: [
            {
                path: 'resources',
                name: 'ResourcesPage',
                component: ResourcesPage
            },
            {
                path: 'resource-actions',
                name: 'ResourceActionsPage',
                component: ResourceActionsPage
            },
            {
                path: 'properties-restrictors',
                name: 'PropertiesRestrictorsPage',
                component: PropertiesRestrictorsPage
            },
            {
                path: 'result-restrictors',
                name: 'ResultRestrictorsPage',
                component: ResultRestrictorsPage
            },
            {
                path: 'permissions',
                name: 'PermissionsPage',
                component: PermissionsPage
            },
            {
                path: 'permission-groups',
                name: 'PermissionGroupsPage',
                component: PermissionGroupsPage
            },
            {
                path: 'user-permission-groups',
                name: 'UserPermissionGroupsPage',
                component: UserPermissionGroupsPage
            },
        ]
    },
    /*{
      path: '/tools',
      component: ToolsPage,
      children: [
        {
          path: 'oss-correction',
          name: 'OSSCorrection',
          component: OSSCorrection
        },
        {
          path: 'oss-correction/:id',
          name: 'OSSCorrectionProjectDetails',
          component: OSSCorrectionProjectDetails
        },
      ]
    },*/
];

/*import {computed} from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [
          {
            slug: 'tools',
            path: '/tools',
            name: 'ToolsPage',
            label: t('menuItems.toolsPage.linkLabel'),
            children: [
              {
                path: '/tools/oss-correction/:id',
                name: 'OSSCorrection',
                label: t('menuItems.toolsPage.children.OSSCorrection.linkLabel')
              },
            ]
          },
        ]);*/


export {
    pages
}