<script setup>
import { to } from "await-to-js";
import { ref, reactive, onMounted, computed } from "vue";
import {cloneDeep, find} from "lodash";
import { interceptor } from "@dutypay/utilities";
import { useEnvStore } from "@dutypay/store-modules";
import { ElNotification } from "element-plus";
const loading = ref(false);
let isAddPermissionDialogVisible = ref(false);

const addPermissionFormBlank = {
  name: "",
  isDeleted: false,
  hs: {
    permission: {
      id: ""
    },
    resource: {
      id: "",
      action: {
        id: ""
      }
    },
    result: {
      restrictor: {
        id: ""
      }
    },
    properties: {
      restrictor: {
        id: ""
      }
    }
  },
  resource: {
    action: {
      value: ""
    }
  },
  description: {
    text: "",
    url: ""
  }
};
const addPermissionForm = ref({
  name: "",
  isDeleted: false,
  hs: {
    permission: {
      id: ""
    },
    resource: {
      id: "",
      action: {
        id: ""
      }
    },
    result: {
      restrictor: {
        id: ""
      }
    },
    properties: {
      restrictor: {
        id: ""
      }
    }
  },
  resource: {
    action: {
      value: ""
    }
  },
  description: {
    text: "",
    url: ""
  }
});

const isEditPermissionDialogVisible =  ref(false);
const editPermissionFormBlank = {
  name: "",
  isDeleted: false,
  hs: {
    permission: {
      id: ""
    },
    resource: {
      id: "",
      action: {
        id: ""
      }
    },
    result: {
      restrictor: {
        id: ""
      }
    },
    properties: {
      restrictor: {
        id: ""
      }
    }
  },
  resource: {
    action: {
      value: ""
    }
  },
  description: {
    text: "",
    url: ""
  }
};
const editPermissionForm = ref({
  name: "",
  isDeleted: false,
  hs: {
    permission: {
      id: ""
    },
    resource: {
      id: "",
      action: {
        id: ""
      }
    },
    result: {
      restrictor: {
        id: ""
      }
    },
    properties: {
      restrictor: {
        id: ""
      }
    }
  },
  resource: {
    action: {
      value: ""
    }
  },
  description: {
    text: "",
    url: ""
  }
});
const permissions = ref([]);
const resources = ref([]);
const resourceActions = ref([]);
const propertiesRestrictors = ref([]);
const resultRestrictors = ref([]);
const queriedPermissionCount = ref(0);
const totalPermissionCount = ref(0);
const envStore = useEnvStore();

const pageSizeOptions = ref([
  {label: '10', value: "10"},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  search: "",
  pageNumber: "1",
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getPermissions()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber,10) > 1;
});
async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) - 1).toString();
  await getPermissions();
}

const hasNextPage = computed(() => {
  return (queriedPermissionCount.value < totalPermissionCount.value);
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) + 1).toString();
  await getPermissions();
}

async function getResources() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/resources`, {})
  );
  if (err) {
    loading.value = false;
    return;
  }
  resources.value = [];
  resources.value = res.data.result;
  loading.value = false;
}

async function getResourceActions() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/resource-actions`, {})
  );
  if (err) {
    loading.value = false;
    return;
  }
  resourceActions.value = [];
  resourceActions.value = res.data.result;
  loading.value = false;
}

async function getResultRestrictors() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/result-restrictors`, {})
  );
  if (err) {
    loading.value = false;
    return;
  }
  resultRestrictors.value = [];
  resultRestrictors.value = res.data.result;
  loading.value = false;
}

async function getPropertiesRestrictors() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/properties-restrictors`, {})
  );
  if (err) {
    loading.value = false;
    return;
  }
  propertiesRestrictors.value = [];
  propertiesRestrictors.value = res.data.result;
  loading.value = false;
}


async function getPermissions() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
    interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/permissions`, {
      params: {
        search: parameters.search,
        pageNumber: parameters.pageNumber,
        pageSize: parameters.pageSize,
      },
    })
  );
  if (err) {
    loading.value = false;
    return;
  }
  permissions.value = [];
  permissions.value = res.data.result.permissionList;
  queriedPermissionCount.value = res.data.result.sqlQueriedPermissionCurrentCount;
  totalPermissionCount.value = res.data.result.sqlQueriedPermissionTotalCount;
  loading.value = false;
}

async function getPermission(id) {
  let err, res;
  [err, res] = await to(
    interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/permissions/${id}`, {})
  );
  if (err) {
    ElNotification({
      title: "Error on getting permission with ID " + id,
      type: "error",
    });
    return;
  }
  return res.data.result;
}

async function addPermission() {
  try{
    await to(
        interceptor.post(
            `${envStore.apiUrls.dutypay}/api/v0/permissions`,
            addPermissionForm.value
        )
    );
    ElNotification({
      title: "Permission successfully added.",
      type: "success",
    });
    loading.value = false;
    addPermissionForm.value = cloneDeep(addPermissionFormBlank);
    isAddPermissionDialogVisible.value = false;
    await getPermissions();
  }
  catch(err){
    loading.value = false;
    ElNotification({
      title: "Error on adding permission",
      type: "error",
    });
    isAddPermissionDialogVisible.value = false;
  }
}

async function handleEditPermission(index, permission){
  let id = permission.hs.permission.id;
  let err,permissionFromServer;
  [err, permissionFromServer] = await to(getPermission(id));
  if(err){
    ElNotification({
      title: "Error on getting permission " + id,
      type: "error",
    });
  }
  editPermissionForm.value = permissionFromServer;
  isEditPermissionDialogVisible.value = true;
}

async function editPermission() {
  try{
    await to(
        interceptor.patch(
            `${envStore.apiUrls.dutypay}/api/v0/permissions/${editPermissionForm.value.hs.permission.id}`,
            editPermissionForm.value
        )
    );
    ElNotification({
      title: "Permission successfully edited.",
      type: "success",
    });
    loading.value = false;
    editPermissionForm.value = cloneDeep(editPermissionFormBlank);
    isEditPermissionDialogVisible.value = false;
    await getPermissions();

  }
  catch(err){
    loading.value = false;
    ElNotification({
      title: "Error on editing permission",
      type: "error",
    });
    isEditPermissionDialogVisible.value = false;
  }
}

function updateRessourceActionToForm(mode){
  if(mode === "add"){
    let resourceAction = find(resourceActions.value, (r) => {return r.hs.resource.action.id === addPermissionForm.value.hs.resource.action.id});
    addPermissionForm.value.hs.resource.action.id = resourceAction.hs.resource.action.id;
    addPermissionForm.value.resource.action.value = resourceAction.name;
  }
  else
  {
    let resourceAction = find(resourceActions.value, (r) => {return r.hs.resource.action.id === editPermissionForm.value.hs.resource.action.id});
    editPermissionForm.value.hs.resource.action.id = resourceAction.hs.resource.action.id;
    editPermissionForm.value.resource.action.value = resourceAction.name;
  }

  //console.log("after addPermissionForm", addPermissionForm.value)
}

onMounted(async () => {
  await getPermissions();
  await getResultRestrictors();
  await getPropertiesRestrictors();
  await getResources();
  await getResourceActions();
});
</script>

<script>
import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "PermissionsPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          Permissions &nbsp;
          <el-button type="success" @click="isAddPermissionDialogVisible = true">
            Add
          </el-button>
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" :xl="8" :lg="8" :sm="24" :xs="24" class="searchform">
        <el-form-item style="flex-grow: 1">
          <el-input
            v-model="parameters.search"
            @click="getPermissions()"
            @change="parameters.pageNumber = 1"
            placeholder="Search for hs.id, name"
            :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getPermissions"> Search </el-button>
        </el-form-item>
      </el-col>
      <el-col
          :span="16"
          :xl="16"
          :lg="16"
          :sm="24"
          :xs="24"
        class="pagesize"
        style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="Permissions per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="permissions" stripe style="width: 100%">
          <el-table-column prop="hs.resource.id" label="HubSystem Resource ID" />
          <el-table-column prop="hs.resource.action.id" label="HubSystem Resource Action ID" />
          <el-table-column prop="resource.action.value" label="Resource Action Value" />
          <el-table-column prop="name" label="Name" />
          <el-table-column prop="description.text" label="Description as text" />
          <el-table-column prop="description.url" label="Description URL" />
          <el-table-column prop="hs.result.restrictor.id" label="HubSystem Result Restrictor ID" />
          <el-table-column prop="hs.properties.restrictor.id" label="HubSystem Property Restrictor ID" />
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="handleEditPermission(scope.$index, scope.row)"
                >Edit</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
      v-model="isAddPermissionDialogVisible"
      title="Add Permission"
      width="1000px"
      align-center
      draggable
    >
      <el-form
        :model="addPermissionForm"
        label-position="left"
        label-width="300px"
        style="max-width: 1000px"
      >
        <el-form-item label="Permission Name">
          <el-input v-model="addPermissionForm.name" />
        </el-form-item>
        <el-form-item label="HubSystem Resource ID">
            <el-select v-model="addPermissionForm.hs.resource.id" clearable placeholder="Select" >
              <el-option
                  v-for="item in resources"
                  :key="item.hs.resource.id"
                  :label="item.name"
                  :value="item.hs.resource.id"
              />
            </el-select>
        </el-form-item>

        <el-form-item label="HubSystem Resource Action ID">
          <el-select v-model="addPermissionForm.hs.resource.action.id" clearable placeholder="Select" @change="updateRessourceActionToForm('add')">
            <el-option
                v-for="item in resourceActions"
                :key="item.hs.resource.action.id"
                :label="item.name"
                :value="item.hs.resource.action.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Description">
          <el-input v-model="addPermissionForm.description.text" />
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="addPermissionForm.description.url" />
        </el-form-item>

        <el-form-item label="HubSystem Property Restrictor">
          <el-select v-model="addPermissionForm.hs.properties.restrictor.id" clearable placeholder="Select" >
            <el-option
                v-for="item in propertiesRestrictors"
                :key="item.hs.properties.restrictor.id"
                :label="item.restrictor.text"
                :value="item.hs.properties.restrictor.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="HubSystem Result Restrictor">
          <el-select v-model="addPermissionForm.hs.result.restrictor.id" clearable placeholder="Select">
            <el-option
                v-for="item in resultRestrictors"
                :key="item.hs.result.restrictor.id"
                :label="item.restrictor.text"
                :value="item.hs.result.restrictor.id"/>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAddPermissionDialogVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="addPermission">
            Add Permission
          </el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
      v-model="isEditPermissionDialogVisible"
      :title="'Edit Permission \'' + editPermissionForm.name + '\''"
      width="1000px"
      align-center
      draggable
    >
      <el-form
        :model="editPermissionForm"
        label-position="left"
        label-width="300px"
        style="max-width: 1000px"
      >
        <el-form-item label="Permission Name">
          <el-input v-model="editPermissionForm.name" disabled/>
        </el-form-item>
        <el-form-item label="HubSystem-Resource-ID">
          <el-select v-model="editPermissionForm.hs.resource.id" clearable placeholder="Select" >
            <el-option
                v-for="item in resources"
                :key="item.hs.resource.id"
                :label="item.name"
                :value="item.hs.resource.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="HubSystem Resource Action ID">
          <el-select v-model="editPermissionForm.hs.resource.action.id" clearable placeholder="Select" @change="updateRessourceActionToForm('update')">
          <el-option
                v-for="item in resourceActions"
                :key="item.hs.resource.action.id"
                :label="item.name"
                :value="item.hs.resource.action.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Description">
          <el-input v-model="editPermissionForm.description.text" />
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="editPermissionForm.description.url" />
        </el-form-item>

        <el-form-item label="HubSystem Property Restrictor">
          <el-select v-model="editPermissionForm.hs.properties.restrictor.id" clearable placeholder="Select" >
            <el-option
                v-for="item in propertiesRestrictors"
                :key="item.hs.properties.restrictor.id"
                :label="item.restrictor.text"
                :value="item.hs.properties.restrictor.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="HubSystem Result Restrictor">
          <el-select v-model="editPermissionForm.hs.result.restrictor.id" clearable placeholder="Select" >
            <el-option
                v-for="item in resultRestrictors"
                :key="item.hs.result.restrictor.id"
                :label="item.restrictor.text"
                :value="item.hs.result.restrictor.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Deleted?">
          <el-checkbox v-model="editPermissionForm.isDeleted" :checked="!!editPermissionForm.isDeleted">Deleted?</el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEditPermissionDialogVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="editPermission">
            Edit Permission
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
