<script setup>
import {to} from "await-to-js";
import {computed, onMounted, reactive, ref} from "vue";
import {isEmpty, omitBy} from "lodash";
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);

const resultRestrictors = ref([]);

const queriedResultRestrictorCount = ref(0);
const totalResultRestrictorCount = ref(0);
const envStore = useEnvStore();

const pageSizeOptions = ref([
  {label: '10', value: '10'},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  pageNumber: "1"
});

const createButtonClicked = ref(false);

const newResultRestristorRecord =
    {
      "restrictor": {"text": "{}"}
    }


async function addNewLine() {
  createButtonClicked.value = true;
  resultRestrictors.value.push(newResultRestristorRecord)
}

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getResultRestrictors()
}

async function deleteResultRestrictors(data) {
  createButtonClicked.value = false;
  console.log("data.cellIndex", (data.cellIndex) - 1);
  resultRestrictors.value.pop();
}


// TODO: Needs an improvement about permission properties if refactoring is done
async function createResultRestrictors(data) {
  loading.value = true;
  createButtonClicked.value = false;
  try {
    data.restrictor.text = JSON.parse(data.restrictor.text);
    let err, res;
    [err, res] = await to(
        interceptor.post(`${envStore.apiUrls.dutypay}/api/v0/result-restrictors`, data)
    );
    if (err) {
      ElNotification({
        title: "Error to update result-restrictor",
        type: "error",
        message: err.message
      });
    } else {
      console.log("res", res.data)
      ElNotification({
        title: res.data.name,
        type: "success",
        message: res.data.message
      });
    }
    loading.value = false;
    await getResultRestrictors(); // refresh page
  } catch (err) {
    ElNotification({
      title: "JSON in model is invalid",
      type: "error",
      message: err.message
    });
    loading.value = false;
  }
}


// TODO: Needs an improvement about permission properties if refactoring is done
async function updateResultRestrictors(data) {
  loading.value = true;
  try {
    JSON.parse(data.restrictor.text);
    let err, res;
    [err, res] = await to(
        interceptor.patch(`${envStore.apiUrls.dutypay}/api/v0/result-restrictors/${data.hs.result.restrictor.id}`, data)
    );
    if (err) {
      ElNotification({
        title: "Error to update result-restrictor",
        type: "error",
        message: err.message
      });
    } else {
      console.log("res", res.data)
      ElNotification({
        title: res.data.name,
        type: "success",
        message: res.data.message
      });
    }
    loading.value = false;
  } catch (err) {
    ElNotification({
      title: "JSON in model is invalid",
      type: "error",
      message: err.message
    });
    loading.value = false;
  }
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber, 10) > 1;
});

async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) - 1).toString();
  await getResultRestrictors();
}

const hasNextPage = computed(() => {
  return (
      queriedResultRestrictorCount.value < totalResultRestrictorCount.value)
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
  await getResultRestrictors();
}

async function getResultRestrictors() {
  createButtonClicked.value = false;
  loading.value = true;
  let err, res;
  const filteredParameters = omitBy(parameters, value => isEmpty(value));

  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/result-restrictors`, {
        params: filteredParameters,
      })
  );

  if (err) {

    if (err.response.data.name === "Not Found Error") {
      resultRestrictors.value = [];
    } else {
      loading.value = false;
      throw err;
    }
  } else {
    resultRestrictors.value = res.data.result.sqlQueriedResultRestrictorList;
  }

  let updatedResultRestrictors = []
  for (let resultRestrictor of resultRestrictors.value) {
    updatedResultRestrictors.push(resultRestrictor)
  }
  resultRestrictors.value = updatedResultRestrictors;
  queriedResultRestrictorCount.value = res.data.result.sqlQueriedResultRestrictorCurrentCount;
  totalResultRestrictorCount.value = res.data.result.sqlQueriedResultRestrictorTotalCount;
  loading.value = false;
}

onMounted(async () => {
  await getResultRestrictors();
});
</script>

<script>

import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "ResultRestrictorsPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Result Restrictors
          <el-button :disabled="createButtonClicked" type="success" @click="addNewLine">
            Add
          </el-button>
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">

      <!--
      <el-col :span="16" :xl="16" :lg="16" :sm="32" :xs="32" class="searchform">
        <el-form-item style="padding-left: 1rem; flex-grow: 1">
          <el-input
              v-model="parameters.searchRouteName"
              @change="parameters.pageNumber = '1'"
              placeholder="Search for route.name"
              :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getResultRestrictors"> Search</el-button>
        </el-form-item>
      </el-col>-->
      <el-col
          :span="8"
          :xl="8"
          :lg="8"
          :sm="24"
          :xs="24"
          class="pagesize"
          style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="ResultRestrictors per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="resultRestrictors" stripe style="width: 100%">
          <el-table-column prop="hs.result.restrictor.id" label="Result Restrictor HubSystem ID"/>
          <!-- TODO: Needs an update in the future about select ids or values -->
          <el-table-column prop="permission.requirement.ids" label="Permission Requirement IDs">
            <template #default="scope">
              <el-input
                  type="textarea"
                  placeholder="Type your JSON here"
                  v-model="scope.row.restrictor.text">
              </el-input>

            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button v-if="scope.row.hs" :disabled="createButtonClicked" type="warning" @click="updateResultRestrictors(scope.row)">
                Save
              </el-button>
              <div v-else>
                <el-button type="warning" @click="createResultRestrictors(scope.row)">
                  Create
                </el-button>
                <el-button type="warning" @click="deleteResultRestrictors(scope)">
                  Cancel (Create)
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
{
  "en-US": {
  }
}
</i18n>
